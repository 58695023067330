import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import Randlet, { Img, Question } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";
import { Input } from "../components/Form";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup, MdOutlineQuestionAnswer } from "react-icons/md";


export default function MyTasks() {

	const { t } = useTranslation();
	const {success, userinfo} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;

	const [loader, setloader] = useState(true);
	const [loader2, setloader2] = useState(false);
	const [data, setdata] = useState([]);
	const [showsendTask, setshowsendTask] = useState(false); 
	const [values, setvalues] = useState({});

	let loc = useParams();
	let nav = useNavigate();

	let selectEd = [
		{
			name: "نهائي",
			val:1
		},
		{
			name: "بانتظار التصليح",
			val:0
		}
	]

	function submit() {

		setloader2(true);
		Fetch("tasks/updateScore", (d) => {

			if (d?.error) {
				setopen({
                    short:d?.error,
                    error:true
                });
			} else {
				setopen({
                    short:"تم تعديل علامة الطالب بنجاح.",
                    error:false
                });

				setTimeout(() => {
					nav(-1);
				}, 2500);
			}

			
			setloader2(false);
		}, values);
	}

	useEffect(() => {
			
		Fetch("tasks/view", (d) => {

			if (!d?.error) {

				setdata(d);

				// let qScore = 100 / d?.tasks[0]?.questions?.length;

				// let allScores = 0;
				// d?.tasks[0]?.questions?.map((v, k) => {
				// 	//
				// 	let ck = d?.tasks[0]?.students[0]?.answers[k]?.answer;
					
				// 	if (v?.answers[ck]?.true) {
				// 		allScores += qScore;
				// 	}
				// });



				setvalues(p => ({
					...p,
					// score:Math.round(allScores),
					score:d?.tasks[0]?.students[0]?.score,
					finished:d?.tasks[0]?.students[0]?.finished,
					_id:loc?.id,
					student:loc?.student,
				}));



			}
			

			
			setloader(false);
		}, { _id: loc?.id, student:loc?.student});

	
	}, []);


	if (loader) {
		return (
			<Loader />
		);
	}

	let path = [
		{
			name:"Home",
			link:"/"
		},
		{
			name:"My Tasks",
			link:"/MyTasks"
		},
		{
			name:data?.tasks[0]?.name,
			link:`/MyTasks/${loc?.id}`
		},
		{
			name:data?.tasks[0]?.students[0]?.name,
			link:`/MyTasks/${loc?.id}/${loc?.student}`
		}
	];

	return (
		
		<>
			{loader2 && <Loader />}
			<Header title={path} />


			<div className='firstElem stats' style={{marginTop:-30}}>

				<div className='mainWidth'>


					<section>
						<div>
							<b>عدد الاسئلة</b>
							<h3>{data?.tasks[0]?.questions?.length}</h3>
							{/* <span className='up'><FaArrowUp /> 10.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>معدل العلامة</b>
							<h3>{data?.stats?.avg}%</h3>
							{/* <span><FaArrowDown /> 2.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>توقيت الانتهاء</b>
							<h3 style={{fontSize:38}}>{data?.tasks[0]?.students[0]?.timer}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>
					<section>
						<div>
							<b>حالة المهمة</b>
							<h3 style={{fontSize:38}}>{values?.finished ? "نهائي" : "بانتظار التصليح"}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>


				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>

						

						<div className='containerBox' style={{flex:1, paddingTop:10 }}>
					
		
							<h3 className='boxTitle' style={{fontSize:20, color:"#555"}}>الاسئلة</h3>

							<div className='quizPrevBox' style={{height:"auto", marginTop:20, }}> 
								<div className='questionsBox' style={{overflow:"unset", padding:"30px", border:0}}>

									<div className='question'>
										<div dangerouslySetInnerHTML={{__html:`${data?.tasks[0]?.story}`}} style={{padding:20}}></div>
									</div>

									{data?.tasks[0]?.questions?.map((v, k) => {

										let ans = data?.tasks[0]?.students[0]?.answers; 
										
										return (
											<Question v={v} answers={ans} key={k} />
										);
										
									})}

									
									
								</div>

								<div className='questionComments'>
									<div>
										<Input 
											title="العلامة" 
											name="user"
											type="number"
											onChange={(v) => setvalues(p => ({
												...p,
												score:v,
											}))}
											value={values?.score}

										/>
									</div>

									<div style={{display:"flex", alignItems:"flex-end"}}>
										<Input 
											title="الحالة" 
											name="user"
											type="select"
											onChange={(v) => setvalues(p => ({
												...p,
												finished:v,
											}))}
											value={values?.finished ? 1 : 0}
											values={selectEd}
											style={{width:200}}
										/>

										<button type="button" onClick={submit} className='btn'>حفظ النتائج</button>
									</div>
									
								</div>

								
							</div>
							
						</div>

						<div className='containerBox' style={{width:"30%", paddingTop:10}}>
						
							<h3 className='boxTitle' style={{fontSize:20, color:"#555"}}>معلومات المهمة</h3>

							<div className='quizInfoBox' >
								<ul>
									<Img src={data?.tasks[0]?.students[0]?.img} />
									<h3>{data?.tasks[0]?.students[0]?.name}</h3>
									<h4>{global.Classes[data?.tasks[0]?.students[0]?.classes[0][0]]} ({global.Grades[data?.tasks[0]?.students[0]?.classes[0][2]]})</h4>
								</ul>
							</div>

							<div className='quizInfoBox'>
								<a>
									<span>الصفوف</span>
									<b>
										{data?.tasks[0]?.classes?.map((v, k) => {
											
											let classNames = global.Classes[v[0]];
											let gradeNames = global.Grades[v[2]];

											return (
												<r key={k}>{classNames} ({gradeNames}), </r>
											);
										})}
									</b>
								</a>
								
								<a>
									<span>التوقيت</span>
									<b>{data?.tasks[0]?.timer ? `${data?.tasks[0]?.timer} دقائق` : "غير محدود"}</b>
								</a>
								<a>
									<span>النتيجة</span>
									<b>{data?.tasks[0]?.res_type ? "تصليح تلقائي" : "تصليح من المعلم"}</b>
								</a>

								<a>
									<span>اضيف ب</span>
									<b>{dateToText(data?.tasks[0]?.time)}</b>
								</a>
								<a>
									<span>بدأ ب</span>
									<b>{dateToText(data?.tasks[0]?.date_start)}</b>
								</a>
								<a>
									<span>ينتهي ب</span>
									<b>{data?.tasks[0]?.date_end ? dateToText(data?.tasks[0]?.date_end) : "Unlimited"}</b>
								</a>

								<a>
									<span>ملاحظات</span>
									<b>{data?.tasks[0]?.full ? data?.tasks[0]?.full : "--"}</b>
								</a>


							</div>
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
	
}
