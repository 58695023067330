import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import SendTask from "./popups/SendTask";
import { Img } from "../components/Elements";

import Footer from "./Footer";
import Header from "./Header";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";

export default function Cp() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	
	const [showsendTask, setshowsendTask] = useState(false); 


	function deleteTask(v) { 

		let conf = window.confirm(t("error",  { returnObjects: true })['delete']);
		if (!conf) {
			return false;
		}

		Fetch("tasks/delete", (d) => {
			
			setdata(p => {
				let arr = [];
				p?.tasks?.map((r) =>{
					if (r?._id != v) {
						arr.push(r);
					}
					
				});
				
				
				return { stats:p?.stats, tasks:arr}
			});

			setloader(false);
		}, { _id: v });

	}

	useEffect(() => {

			
		Fetch("tasks/view", (d) => {

			if (!d?.error) {
				setdata(d);
			} else {
				setopen({
                    title:t("error",  { returnObjects: true })['all'],
                    short:d?.error,
                    error:true
                });
			}

			setloader(false);
		});

	}, []);

	// console.log(data);

	return (
		
		<>
			{showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}
			<Header />
			<div className='firstElem stats' style={{marginTop:-20}}>

				<div className='mainWidth'>

					<section>
						<div>
							<b>عدد المهام المحوسبة</b>
							<h3>{data?.stats?.tasks}</h3>
						</div>
					</section>
					<section>
						<div>
							<b>عدد الطلاب</b>
							<h3>{data?.stats?.students}</h3>
						</div>
					</section>

					<section>
						<div>
							<b>معدل علامات الطلاب</b>
							<h3>{data?.stats?.avg}%</h3>
						</div>
					</section>



				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>

						<div className='containerBox' style={{width:300}}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle'>الحساب</h3>

							<ul className='profile'>
								<div className='pImg'>
									<Img src={'img/100x100' + logedIn?.userinfo?.img} />
									<h5>{logedIn?.userinfo?.name}</h5>
								</div>
								<li><span>المستخدم:</span><b>{logedIn?.userinfo?.user}</b></li>
								<li><span>درجة العضوية:</span><b>{logedIn?.userinfo?.userlevel == 1 && "Student"}{logedIn?.userinfo?.userlevel == 3 && "Teacher"}{logedIn?.userinfo?.userlevel == 5 && "Manager"}</b></li>
								<li><span>الاسم الكامل:</span><b>{logedIn?.userinfo?.name}</b></li>
								<li><span>حالة الحساب:</span><b>{logedIn?.userinfo?.status >= 1 ? "Active" : "Deactive"}</b></li>
								<li><span>مكان الاقامة:</span><b>{logedIn?.userinfo?.city}</b></li>
								<li><span>المدرسة:</span><b>{logedIn?.userinfo?.school_id[0]?.name}</b></li>
								<li><span>الصفوف:</span><b>{logedIn?.userinfo?.classes?.map((c, kk) => {
										let classNames = `${global.Classes[c[0]]} (${global.Grades[c[2]]})`
										return (
											<span key={kk} className='tag' style={{margin:"4px auto", color:"#fff"}}>{classNames}</span>
										);
									})}	</b></li>
							</ul>
						</div>


						<div className='containerBox' style={{flex:1, paddingTop:10 }}>
							
							<h3 className='boxTitle'>مهامي المحوسبة</h3>

							<div className='quizzesList'>
								<table>
								<thead>
										<tr>
											<td style={{width:"40%"}} className='align-left'>المهمة</td>
											<td>العلامة</td>
											<td>الصفوف</td>
											<td>بالانتظار</td>
											<td style={{width:"20%"}}>الإجراءات</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.tasks?.map((v, k) => {
											return (
												<tr style={ v?.not > 0 ? { backgroundColor:"#ffeedc"} : {}} key={k}>
													<td className='align-left'><Link to={`/MyTasks/${v?._id}`} ><b>{v?.name}</b> {v?.full && <><br /><p>{v?.full}</p></>}</Link></td>
													<td>{v?.avg ? <span className='tag green'>{v?.avg}%</span> : "--"}</td>
													<td>
														{v?.classes?.map((c, kk) => {

															let clsSplit = c?.split("-");
															let classNames = `${global.Classes[clsSplit[0]]} (${global.Grades[clsSplit[1]]})`
															return (
																<span key={kk} className='tag' style={{margin:"4px auto"}}>{classNames}</span>
															);
														})}
													</td>
													<td>{v?.not}</td>
													<td>
														<Link to={`/MyTasks/${v?._id}`} className='tableOptions blue'><MdOutlineGroup /></Link>
														&nbsp;&nbsp;
														<a onClick={() => deleteTask(v?._id)} href='javascript:void(0)' className='tableOptions red'><MdDeleteOutline /></a>
														&nbsp;&nbsp;
														<a onClick={() => setshowsendTask(v)} href='javascript:void(0)' className='tableOptions green'><MdEdit /></a>
														
														
													</td>
												</tr>
											);
										})}
										
									</tbody>
								</table>
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
}
