import { memo, useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";

import './css/QuizSend.css'
import manSrc from '../../images/moe.png'
import { Editor } from '@tinymce/tinymce-react';

import { Config } from '../../inc/Config'
import { AppContext } from "../../Context";
import Popups from "../../components/Popups";
import QuestionAdd from "./QuestionAdd";

import Fetch, { Loader, dateToText } from "../../inc/Fetch";
import { Input, MultiSelect } from "../../components/Form";

import { MdDeleteOutline } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";

import { Question } from "../../components/Elements";

export default memo(function SendTasks({setopen, open}) {
    
    const { t } = useTranslation();
    const editorRef = useRef(null);
    const start = useRef(true);
    const active = useRef(true);

    const {success, userinfo, languageText, showQuestion, cartData} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [text, settext] = languageText;
    const [showQ, setshowQ] = showQuestion;
    const [cart, setcart] = cartData; 

    const [miniTabs, setminiTabs] = useState(0);

    const [values, setvalues] = useState({res_type:1 });
    const [loader, setloader] = useState(false);
    const [questions, setquestions] = useState([]);
    const [story, setstory] = useState([]);

    const [ploader, setploader] = useState(open?._id ? true : false);

    const [myClasses, setmyClasses] = useState([]);

    const [openNewQuestion, setopenNewQuestion] = useState(false);
    const [students, setstudents] = useState([]);

    const [checked, setchecked] = useState([]);

    const timer = [
        {
            name:"بلا تحديد",
            val:0
        },
        {
            name:"5 دقائق",
            val:5
        },
        {
            name:"10 دقائق",
            val:10
        },
        {
            name:"15 دقائق",
            val:15
        },
        {
            name:"30 دقائق",
            val:30
        },
        {
            name:"45 دقائق",
            val:45
        },
        {
            name:"ساعة واحدة",
            val:60
        },
        {
            name:"ساعتين",
            val:120
        }
    ];

    const qres = [
        {
            name:"اظهار العلامة تلقائيا",
            val:1
        },
        {
            name:"سيقوم المعلم بالتصليح",
            val:0
        }
    ];

    function submit(e) {
        e.preventDefault();

        setloader(true);

        values.story = editorRef.current.getContent();

        Fetch("tasks/add", (d) => {

            if (d?.error) {
                setsuc({
                    short:t("error",  { returnObjects: true })[d?.error],
                    error:true
                });
            } else {
                setsuc({
                    short:"تم ارسال المهمة المحوسبة بنجاح.",
                    error:false,
                    redirect:"/MyTasks"
                });

                setTimeout(() => {
                    setopen(false);
                }, 2500);


            }

            setloader(false);
        }, { options:values, questions:questions });

    }

    
    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url?.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    function deleteQuestion(data) { 
        let conf = window.confirm("You are about to delete a question! are you sure?");
        if (!conf) {
            return false;
        }

        setquestions(p => p?.filter(r => r?.ID != data));
        
    }


    function loadStudent(v) {

        setvalues(p => ({
            ...p,
            classes:v,
        }));


        Fetch("students/view", (d) => {

            if (d?.students?.length) {
                let arr = [];
                d?.students?.map((v) => {
                    arr.push({ name: v?.name, val:v?._id});
                });
                setstudents(arr);
            }

            setloader(false);
        }, { classes: v});
    }

    function timeToInput(dS) {

        let Month = (new Date(dS).getMonth() + 1);
        Month = Month < 10 ? `0${Month}` : Month;
        
        let Day = new Date(dS).getDate();
        Day = Day < 10 ? `0${Day}` : Day;
        
        let Hour = new Date(dS).getHours();
        Hour = Hour < 10 ? `0${Hour}` : Hour;
        
        let Min = new Date(dS).getMinutes();
        Min = Min < 10 ? `0${Min}` : Min;
        
        
        
        let date_start = `${new Date().getFullYear()}-${Month}-${Day}T${Hour}:${Min}`;

        return date_start;
    }

    function checkedQuestions(checked, data) {

        if (checked) {
            setchecked(p => [...p, data]);
        } else {
            setchecked(p => p?.filter(r => r != data));
        }
    }

    function checkAll() {
        
        if (checked?.length) {
            setchecked([]);

        } else {
            let arr = [];
            questions?.map((v) => {
                arr.push(v?.ID);
            });
    
            setchecked(arr);
        }

    }

    function deleteAll() {
        let conf = window.confirm(t("error",  { returnObjects: true })['delete']);
        if (!conf) {
            return false;
        }

        setcart(p => {

            let arr = [];
            p?.map((v) => {


                let filt = checked?.filter(r => r ==v?.ID);

                if (!filt?.length) {
                    arr.push(v);
                }
                
            });


            setchecked([]);
            return arr;
        })
        
    }

    useEffect(() => {
        let clsArr = [];

        if (!logedIn?.userinfo?._id) {
            setploader(true);
            setsuc({
                title:"Ops!!",
                short:"You are not logged in, you do not have a permission to access this section.",
                error:true
            });

            setTimeout(() => {
                setopen(false);
                setploader(false);
            }, 2500);

        } else {

            logedIn?.userinfo?.classes?.map(v => {
                let spCls = v?.split("-");
                clsArr.push({ name:`${global.Classes[spCls[0]]} (${global.Grades[spCls[1]]})`, val:v });
            });

            
            let stu = [];
            let stu2 = [];
            open?.students_list?.map(v => {
                stu.push(v?._id);
                stu2.push({ name:v?.name, val:v?._id });
            });

            setmyClasses(clsArr);
            setstudents(stu2);

            let dS = open?.date_start || new Date();
            let dE = open?.date_end || false;
            let date_start = timeToInput(dS);
            
            
            if (open?._id) {

                let arr = {
                    _id:open?._id,
                    name: open?.name,
                    classes: open?.classes,
                    date_start: date_start,
                    story: open?.story,
                    timer:open?.timer,
                    res_type:open?.res_type || 0,
                    full:open?.full
                }
                if (dE) {
                    let date_end = timeToInput(dE);
                    arr.date_end = date_end;
                }

                arr.students = stu;

                setvalues(arr);

                setquestions(open?.questions);

                setTimeout(() => {
                    setploader(false);
                }, 100);

            } else {
                
                setvalues(p => ({
                    ...p,
                    date_start:date_start,
                    story:open?.story,
                }));
                setquestions(open?.questions);
            }
        }
            

    }, []);


    /*

     // useEffect(() => {
        
    //         if (!values?.classes?.length) {
    //             setstudents([]);
            
    //         } else {

    //             setloader(true);
    //             Fetch("students/view", (d) => {

    //                 if (d?.students?.length) {
    //                     let arr = [];
    //                     d?.students?.map((v) => {
    //                         arr.push({ name: v?.name, val:v?._id});
    //                     });
    //                     setstudents(arr);
    //                 }
            
    //                 setloader(false);
    //             }, { classes: values?.classes});
    //         }
        
    // }, [values?.classes])

    */

    

    if (ploader) {
        return (<Loader />);
    }

    return (

        <>

        {openNewQuestion && <QuestionAdd setOpen={setopenNewQuestion} Open={openNewQuestion} setquestions={setquestions} />}
        
        <Popups setOpen={setopen} style={{maxWidth:600, borderTop:"4px solid #fd745a", maxHeight:"96vh"}}>
            {loader && <Loader />}
           <>

                <div className='popupBody interface data quizSend' >

                    <div className='dataHead' style={{height:60}}>
                        <nav >
                            <a onClick={() => setminiTabs(0)} className={miniTabs == 0 ? 'checked' : "none"}>الاسئلة</a>
                            <a onClick={() => setminiTabs(3)} className={miniTabs == 3 ? 'checked' : "none"}>القصة</a>
                            <a onClick={() => setminiTabs(1)} className={miniTabs == 1 ? 'checked' : "none"}>الاعدادات</a>
                            <a onClick={() => setminiTabs(2)} className={miniTabs == 2 ? 'checked' : "none"}>الخيارات</a>
                        
                        </nav>
                    </div>

                    <form onSubmit={submit}>
                        <div className={`tabsSection ${miniTabs == 0 && "active"}`}>
                            <div className='questionsBoxSend'>
                                <div style={{display:"flex", gap:5}}>
                                    <button onClick={() => setopenNewQuestion(true)} className='btn green' type='button' style={{fontSize:14, padding:"4px 5px 4px 10px", marginBottom:10}}><IoIosAdd style={{fontSize:24}} />سؤال جديد</button>
                                    {checked?.length ? <button onClick={deleteAll} className='btn red' type='button' style={{fontSize:14, padding:"4px 5px 4px 10px", marginBottom:10}}><MdDeleteOutline style={{fontSize:20}} />حذف</button>: false}
                                </div>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <td className='align-left' style={{width:"70%"}}>السؤال</td>
                                            <td>النوع</td>
                                            <td><input className='checkBoxTable' type='checkbox' onChange={(e) => checkAll(e?.target?.checked)} name='ckal' value={true} /></td>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {cart?.length ? cart?.map((v, k) => {
                                            

                                            let checkedBox = checked?.filter(r => r == v?.ID);
                                            
                                            return (
                                                <tr key={k}>
                                                    <td className='align-left'><a onClick={() => setshowQ(v)}>{v?.question}{v?.video?.video_title}{v?.file?.file_title}</a></td>
                                                    <td><span className='tag'>{v?.type == 1 && "Text"}{v?.type == 2 && "Video"}{v?.type == 3 && "Audio"}{v?.type == 4 && "Image"}{v?.type == 5 && "Audio"}</span></td>
                                                    <td>
                                                        {/* <a onClick={() => deleteQuestion(v?.ID)} className='options delete'><MdDeleteOutline /></a> */}
                                                        <input className='checkBoxTable' type='checkbox' onChange={(e) => checkedQuestions(e?.target?.checked, v?.ID)} checked={checkedBox?.length ? true : false} name='qq' value={true} />
                                                    </td>
                                                </tr>
                                            );
                                        }): false}
                                        
                                    </tbody>
                                </table>
                                
                                {!questions?.length ? <div className='noData'>المعلومات في هذا القسم غير متوفرة.</div> : false}

                                <div style={{display:"flex", gap:5}}>
                                    <button type='button' onClick={() => setminiTabs(1)}  className='btn btnQuizSend'>التالي</button>
                                </div>


                            </div>
                        </div>

                        <div className={`tabsSection ${miniTabs == 1 && "active"}`}>

                            <Input 
                                title="عنوان المهمة" 
                                name="taskname"
                                type="text"
                                validate={true}
                                
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    name:v,
                                }))}
                                value={values?.name}
                            />


                            <MultiSelect 
                                title="الصفوف" 
                                name="Classes"
                                type="select"
                                validate={true}
                                
                                onChange={(v) => loadStudent(v)}
                                values={myClasses}
                                value={values?.classes}
                            />

                            {values?.classes?.length ? <MultiSelect 
                                title="الطلاب" 
                                name="students"
                                type="select"
                                
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    students:v,
                                }))}
                                values={students}
                                value={values?.students}
                            />: false}


                            <Input 
                                title="ابتداء المهمة" 
                                name="date_start"
                                type="datetime-local"
                                placeholder="dd/mm/yy"
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    date_start:v,
                                }))}
                                value={values?.date_start}
                            />

                            <Input 
                                title="انتهاء المهمة" 
                                name="date_end"
                                type="datetime-local"
                                placeholder="dd/mm/yy"
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    date_end:v,
                                }))}
                                value={values?.date_end}
                            />

                            

                            <div style={{display:"flex", gap:5}}>
                                <button type="button" onClick={() => setminiTabs(0)} className='btn btnQuizSend gray'>السابق</button>
                                <button type="button" onClick={() => setminiTabs(2)} className='btn btnQuizSend'>التالي</button>
                            </div>
                            
                        </div>


                        <div className={`tabsSection ${miniTabs == 3 && "active"}`}>
                            
                            <Editor 
                                
                                apiKey='u3ru69nq06tfrhaljxpzrdd42fal24zruoz5r0se0yirdxh6'
                                onInit={(_evt, editor) => editorRef.current = editor} 
                                
                                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                                initialValue={values?.story}
                                init={{
                                    license_key: 'gpl',
                                    setup: function (editor) {
                                        editor.ui.registry.addButton("testBTN", {
                                            icon: 'image',
                                            tooltip: "הוספת תמונות", 
                                            onAction: (e) => {
                                                setOpen(true);
                                            }
                                        });
                                    },
                                    height: 400,
                                    menubar: false,
                                    statusbar: false,
                                    language:"ar",
                                    directionality :"rtl", 
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'directionality'
                                    ],
                                    toolbar1: 'bold italic forecolor fontsize alignleft aligncenter ' +
                                        'alignright alignjustify | numlist link ',
                                    // toolbar2: 'media',
                                    content_style: 'body { font-family:Arial; font-size:14px; direction:ltr }'
                                }}
                            />

                        </div>

                        <div className={`tabsSection ${miniTabs == 2 && "active"}`}>
                            <Input 
                                title="التوقيت"
                                name="timer"
                                type="select"
                                
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    timer:v,
                                }))}
                                values={timer}
                                value={values?.timer}
                            />

                            <Input 
                                title="النتائج"
                                name="res_type"
                                type="select"
                                
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    res_type:v,
                                }))}
                                values={qres}
                                value={values?.res_type}
                            />


                            <Input 
                                title="ملاحظات"
                                name="comments"
                                type="textarea"
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    full:v,
                                }))}
                                value={values?.full}
                            />

                            <div style={{display:"flex", gap:5}}>
                                <button type="button" onClick={() => setminiTabs(1)} className='btn btnQuizSend gray'>السابق</button>
                                <button className='btn btnQuizSend'>ارسل المهمة</button>
                            </div>
                            
                        </div>



                    </form>
                    

                    
                </div>
            </> 

            

        </Popups>
        </>
    );
})