import { memo, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import './css/Popups.css';
import { MdOutlineClose } from "react-icons/md";

export default memo(function Popup({children, setOpen, style, index, back}) {

    const navigate = useNavigate();

    function closePopup(e) {
        const cls = e?.target?.getAttribute("class");
        if (cls == "popupBox") {
            if (back) {
                navigate(-1);
            } else {
                setOpen(false);
            }
        }
    }

    if (!index) {
        index = 11;
    }

    useEffect(() => {

        const elem = document.getElementsByClassName("popupBox")[0];  
        elem.addEventListener("click", closePopup);

        return () => {
            elem.removeEventListener("click", closePopup);
        };

    }, []);


    return (
        <div className='popupBox' style={{zIndex:index}}>
            <div className='popup' style={style}>
                <a className='closePop' onClick={() => back ? navigate(-1) : setOpen(false)}><MdOutlineClose size={20}/></a>
                {children}
            </div>
        </div>
    );
    
})