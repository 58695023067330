

const hostName = process.env.NODE_ENV == "development" ? `http://localhost:8443/arabic/` : `${window.location.protocol}//${window.location.host}/API/arabic/`;
const hostImg = process.env.NODE_ENV == "development" ? `http://localhost:8443/` : `${window.location.protocol}//${window.location.host}/API/`;


let Config = {
    
    key: "jJIyxe1P9fVfq3XgzZs12QIKIP1DMYzYo",
    domain: hostName,
    appName: `"Rmobile"`,
    imgUrl: hostImg,
    uploadFolder:"Files/",

    pay_api_key: "9c12a91a195395bc7e7335b3d9938f87acc46112",
    pay_api_pass: "rmobile",
    pay_api_masof: "5601711420", 

    formatAllowed:"image/png,image/jpeg,image/gif,audio/mpeg"

}


export { Config }