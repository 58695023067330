import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ar from './locales/ar/ar.json'

export const languageRes = {
    ar: { translation: ar },
}


i18n.use(initReactI18next).init({
    compatibiliyJSON: "v3", 
    lng: "ar",
    fallbackLng: "ar",
    lazy:true,
    resources: languageRes,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});


export default i18n;