import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

import './css/Header.css'
import { AppContext } from "../Context.js";
import logo from "../images/logo.png";
import bgImg from "../images/bg3.jpg";
import Login from "./popups/Login.js";
import Dictionary from "./popups/Dictionary.js";
import Fetch, { Loader, dateToText } from '../inc/Fetch.js'
import { Config } from '../inc/Config.js'
import { MainMenu, MainUserMenu } from "../components/Menu.js";
import { Img, LanguageSelect } from "../components/Elements.js";
import Vid from '../images/vid2.mp4'

import { IoIosArrowDown, IoMdArrowDropright } from "react-icons/io";
import { IoMenu } from "react-icons/io5";


export default function Header({small, title, data, size}) {

    if (!title) {
        title = "";
    }

    const loc = useLocation();
    const navigate = useNavigate();

    const {success, userinfo, language, languageText, loginForm} = useContext(AppContext);
    const [open, setopen] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [lang, setlang] = language;
    const [text, settext] = languageText;
    const [openLogin, setopenLogin] = loginForm;

    const [mobileMenu, setmobileMenu] = useState(false);
    const [openDic, setopenDic] = useState(false);
    const [access, setaccess] = useState(false);

    // function accessb() {
        
    // }

    useEffect(() => {
        
        if (logedIn?.userinfo?._id) {
            
            Fetch("tasks/tasks_stats", (d) => {
                
                if (d?.error == "login"){
                    setlogedIn({});
                }
                setlogedIn(p => ({
                    ...p,
                    tasks_num:d?.num,
                }))
            });
        }

        setmobileMenu(false);
    }, [loc?.pathname])


    return (
        <>
            {openLogin && <Login setopen={setopenLogin} open={openLogin} />}
            {openDic && <Dictionary setopen={setopenDic} />}
            
            <header>
                <nav>
                    <div className='mainWidth'>
                        
                        
                        <div style={{display:"flex", gap:30, alignItems:"flex-end"}}>
                            <Link to="/" className='logo'>
                                {/* <img src={logo} /> */}
                            </Link>
                            <div className='left'>
                                <MainMenu />
                            </div>
                        </div>

                        {logedIn && 
                        <>
                        {logedIn?.userinfo?._id ? 
                            <div className='right'>
                            
                                <ul role="tab" tabindex="0" onKeyDown={(e) => {
                                    if(e.key === 'Enter'){
                                        setaccess(p => !p)
                                    }
                                }} onClick={(e) => {

                                    setaccess(p => !p)
                            
                                }} className={`userBox ${access ? "openMenu" : ""}`}>
                                    <div>
                                        {logedIn?.tasks_num ? <b>{logedIn?.tasks_num}</b> : false}
                                        <IoIosArrowDown />
                                        <Img src={"img/60x60" + logedIn?.userinfo?.img} />
                                    </div>

                                    {access && <MainUserMenu setopenDic={setopenDic}/>}
                                    
                                </ul>

                                {/* <LanguageSelect /> */}
                        
                            </div>
                        : 
                            <div className='right'>
                                
                                <Link onClick={() => setopenLogin(p => !p)} className='btn'>
                                    هيا نبدأ
                                </Link>

                                {/* <LanguageSelect /> */}

                            </div>
                        }

                        </>
                        }
                        
                        <div className='mobileScreen menuCont' >

                            {logedIn?.userinfo?._id ? <div onClick={() => navigate('/Cp')}>
                                {logedIn?.tasks_num ? <b>{logedIn?.tasks_num}</b> : false}
                                <IoIosArrowDown />
                                <Img src={logedIn?.userinfo?.img} />
                            </div>:

                            <Link onClick={() => setopenLogin(p => !p)} className='btn'>
                                {text?.lets_start}
                            </Link>
                            }
                            <a onClick={() => setmobileMenu(p => !p)} className='menuBtn'><IoMenu /></a>
                            

                        </div>
                        
                        
                    </div>
                    
                </nav>
                    
            </header>
            <div className={`mobileScreen slideMenu ${mobileMenu ? 'open' : 'closed'}`}>
                <a onClick={() => setmobileMenu(false)} className='black' />
                <div className={`menuBox`}>

                    {logedIn?.userinfo?._id && <Link className='userImgIn'>
                        
                        <Img src={"img/60x60" + logedIn?.userinfo?.img} />
                        <div >
                            <h3>{logedIn?.userinfo?.name}</h3>
                            <h4>
                                {logedIn?.userinfo?.userlevel == 1 && text?.you_are_loged_student}
                                {logedIn?.userinfo?.userlevel == 3 && text?.you_are_loged_teacher}
                            </h4>
                        </div>
                        <IoMdArrowDropright />
                    </Link>}


                    <div className='allMenusMobile'>

                        <nav className='regularMenu'>
                            <MainMenu />
                        </nav>

                        {logedIn?.userinfo?._id && <nav className='userMenu'>
                            <MainUserMenu setopenDic={setopenDic}/>
                        </nav>}
                    </div>
                    

                </div>
            </div>
            
            {loc?.pathname == "/" && <div className='marqueeContent'>
                <div className='marqueeBox'>
                    {data?.map((v, k) => {
                        return (
                            <Link to={`/Article/${v?._id}`}>
                                <time>{dateToText(v?.time, "date")}: </time>
                                <h5>{v?.title}</h5>
                            </Link>
                        );
                    })}
                    
                    
                </div>
            </div>}

            {loc?.pathname == "/" ? <div style={small && { height:60}} className={`backGMain `}>
                
                {!small && 
                <>
                    

                    <div className='aboveVideo'>

                        <video autoPlay muted loop>
                            <source src={Vid} type="video/mp4" />
                        </video>

                    </div>
                </>}

                
                {!small && 
                    <>
                    {loc?.pathname == "/" ? 
                        <div className='effect'>
                            
                            <div className='mainWidth'>

                                <div className='textEffect'>
                                    {/* <h1>ابدأ رحلتك الآن </h1> */}
                                    <h2>دروس تفاعلية ومبسطة تناسب جميع المستويات.</h2>
                                    <h2 style={{background:"#31b388"}}>تمارين تطبيقية لتعزيز الفهم والممارسة.</h2>
                                    <h2 style={{background:"#ff5e5e"}}>مواد تعليمية غنية بالفيديوهات والألعاب التعليمية.</h2>
                                    <h2 style={{background:"#1f94d8"}}>فيديوهات وألعاب تعليمية تجعل التعلم ممتعًا.</h2>
                                    {/* <Link to={`/Article/${data?._id}`} className='btn'>المزيد...</Link> */}
                                </div>
                            </div>
                        </div>

                    :
                        <div style={{height:100}}>
                            sadasd
                        </div>
                    }
                    </>
                }
            </div>: 
                <div style={{height:70}}>
                    
                </div>
            }
        </>
    )
}
