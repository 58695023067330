import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";
import { useInView, InView } from "react-intersection-observer";

import { MdOutlineZoomInMap, MdZoomIn, MdZoomOut } from "react-icons/md";

import './css/Books.css'
import { AppContext } from "../Context";
import { Loader, GET } from '../inc/Fetch'
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import { MainUserMenu } from '../components/Menu';
import { Img } from '../components/Elements';
import { Config } from '../inc/Config';

function Page({ data, k }) {

	return (
		<div id={`p-${k+1}`} key={k} data-page={k +1} className='page'>
			<img
				src={`${Config?.imgUrl}${data}`}
			/>
		</div>
	);
	
}

export default function Books() {

	const active = useRef(true);
	const params = useParams();
	var timer = null;

	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;
	const [user, setuser] = userinfo;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [page, setpage] = useState(1); 

	let z = 1;
	function zoom(type) {
		const elem = document.querySelector(".bookPagesListBox");
		if (type) {
			z = z + 0.1;
			elem.style.zoom = z
		} else {
			z = z - 0.1;
			elem.style.zoom = z
		}
		
	}

	function openFullscreen() {

		const fullScreenCk = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

		var elem = document.documentElement;

		if (!fullScreenCk) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen();
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}

	async function loadData() { 

		setpageLoad(true);

		let res = await GET(`books/viewOne/${params?.id}`);
		setdata(res?.book);

		setpageLoad(false);
	}

	function setBookPage(e) {
		if (e?.key == "Enter") {

			if (e?.target?.value >= data?.book?.length + 1) {
				setopen({ error: true, short: "هذه الصفحة غير متوفرة في الكتاب." });
				return false;
			}

			const element = document.getElementById(`p-${e?.target?.value}`);
			element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

			setpage(e?.target?.value);
		}
		
	}
	function obCallback(payload) {
		

		const elem = document.elementFromPoint(500, 200);
		const ck = elem.closest(".page")
		if (ck) {
			let pageN = ck.getAttribute("data-page");
			if (pageN) {
				setpage(Number(pageN))
			}
			
		}
		
	}
	  
	function onScrollSc(e) {

		if(timer !== null) {
			clearTimeout(timer);        
		}
		timer = setTimeout(function() {
			obCallback();
		}, 150);
	}

	useEffect(() => {
		window.addEventListener('scroll', onScrollSc, false);

		return () => {
			window.removeEventListener('scroll', onScrollSc, false);

		}
	}, [])
	

	useEffect(() => {
		loadData();
	}, []);

	if (pageLoad) {
		return <Loader />
	}

	return (
		<>
			<div className='bookPagingBox'>
				<input type='number' value={page} onChange={(e) => setpage(e?.target?.value)} onKeyDown={setBookPage} />
			</div>

			<nav className='bookHeaderBox'>
				<ul className='right'>
					<a onClick={openFullscreen}><MdOutlineZoomInMap /></a>
					<a onClick={() => zoom(true)}><MdZoomIn /></a>
					<a onClick={() => zoom()}><MdZoomOut /></a>
				</ul>

				<h1>{data?.name?.name}</h1>

				<div className='left'>
					{!user?.userinfo?._id ? 
						<b>مرحبا, انت زائر</b>
					:
						<div className='outterUserBox'>
							<a className='mainLink'><Img src={`img/40x40${user?.userinfo?.img}`} />{user?.userinfo?.name}</a>
							<MainUserMenu />
						</div>
					}
				</div>
			</nav>


			<div className='bookPagesListBox'>
				
					{data?.book?.map((v, k) => {
						return (
							<Page k={k} data={v} key={k} />
						);
					})}
			</div>
			
		</>
	)
}
