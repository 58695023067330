import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";
import { MdArrowRight } from "react-icons/md";

import './css/Tasks.css'
import { AppContext } from "../Context";
import Fetch, { Loader } from '../inc/Fetch'
import { Tasks, Img } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

const levels = [
    {
        name:"- جميع الدرجات -",
        val:false,
    },
    {
        name:"مبتدأ",
        val:"Beginners",
    },
    {
        name:"متوسط",
        val:"Intermediate",
    },
    {
        name:"متقدم",
        val:"Advanced",
    }
];


import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { GiShare } from "react-icons/gi";
import { IoIosAdd } from "react-icons/io";


export default function Cp() {

	let params = useParams();
	const active = useRef(true);

	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	

	

	// useEffect(() => {

	// 	setfilter(p => ({
	// 		...p,
	// 		cats:params?.id,
	// 	}))
	

	// }, [params?.id]);

	useEffect(() => {

		setpageLoad(true);
		let fi = filter;
		fi.cats = params?.sub || params?.id;

		Fetch("Quiz/view", (d) => {

			if (!d) {
				setopen({
					title:text?.alertErrorTitle,
					short:text?.cant_get_data,
					error:true
				});
			} else {

				setdata(d);

				let arrCats = [];
				d?.cats?.map((v) => {
					if (!v?.sub?._id) {
						arrCats.push({ name: v?.name, val:v?._id});
					}
				});
				
				setcats(arrCats);

				let arrClas = [{ name: `- جميع الصفوف -`, val:false}];
				Object.keys(global.Classes)?.map((v) => {
					arrClas.push({ name: `الصف ` + global.Classes[v], val:v});
				});
				setcls(arrClas);
			}

			setpageLoad(false);
		}, { filter:fi});
		
		
	}, [filter, params?.id, params?.sub]);

	let subCats = data?.cats?.filter(p => p?.sub?._id == params?.id);
	
	return (
		
		<>
			
			<Header />
			<div className='firstElem search'>
				<div className='mainWidth'>

					<div className='filterBoxTop'>

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={levels}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                level:v,
                            }))}
                            value={filter?.level}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cls}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                classes:v,
                            }))}
                            value={filter?.classes}
                        />
			


					</div>

				</div>
			</div>

			<section className='sectTasks'>
				<div className='mainWidth'>

					<div className='left'>
						<h4>الاقسام</h4>
						<nav>
							<Link to={`/Tasks`} className={`mainCatsLink ${!params?.id && 'selected'}`}><MdArrowRight /> جميع الاقسام</Link>
							{cats?.map((v, k) => {

								return (
									<Link key={k} to={`/Tasks/${v?.val}`} className={`mainCatsLink ${params?.id == v?.val && 'selected'}`}><MdArrowRight /> {v?.name}</Link>
								);
							})}
							
						</nav>
					</div>

					<div className='right'>
						{pageLoad ? <Loader /> :
							<>
								{params?.id && subCats?.length ? <div className='subCatsBox'>
									<h2>الاقسام الثانوية</h2>
									{subCats?.map((v, k) => {
										
										return (
											<Link to={`/tasks/${params?.id}/${v?._id}`}>
												<Img src={'img/140x100' + v?.img} />
												<h3>{v?.name}</h3>
											</Link>
										)
									})}
								</div>: false}
								
								<div className='productsInTask'>
									<h2>الدروس المحوسبة</h2>
									
									{data?.tasks?.length ? data?.tasks?.map((v, k) => {
										return (
											<Tasks key={k} data={v} />
										);
									})
									
									:
										<div></div>
									}

								</div>
								
							</>


							
							

						}
					</div>
				</div>

			</section>
			{/* <section className='sect5 tasks' style={{padding:'10px 0 50px 0'}}>

				{pageLoad ? <Loader /> :

				<div className='mainWidth'>

					<di

					<div className='sect5Box just4' style={{marginTop:10}}>
						{data?.tasks?.length ? data?.tasks?.map((v, k) => {
							return (
								<Tasks key={k} data={v} />
							);
						})
						
						:
							<div></div>
						}

					</div>
					
					
				</div>}
			</section> */}

			<Footer />
		</>
	)
}
