import { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";

import { IoCheckmark, IoCloseOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

import { AppContext } from "../Context";
import './css/Success.css';

export default function Success({data}) {

    const active = useRef(true);
    const {success} = useContext(AppContext);
    const [open, setopen] = success;

    const navigate = useNavigate();
    
    const [title, settitle] = useState(data?.error ? "اوبس!!" : "احسنت!!");
    const [short, setshort] = useState(data?.error ? "" : "لقد تم حفظ المعلومات بنجاح٫");
    const [error, seterror] = useState(false);
    

    useEffect(() => {

        if (active.current)  {
            
            active.current = false;
            if (data?.title) {
                settitle(data?.title);
            }
            if (data?.short) {
                setshort(data?.short);
            }
            
            if (data?.error) {
                seterror(true);
            }
            
            setTimeout(() => {
                if (data?.redirect) {
                    navigate(data?.redirect);
                }

                if (data?.short == "login") {
                    window.location.reload();
                }
                
                setopen(false);
                
            }, 2500);
        }
    }, []);


    return (
        <div className='successWhite'>
            <div className={error ? 'successBox error' : 'successBox'}>
                <p>
                    <b><FaCheck /></b>
                </p>
                <div className='text'>
                    <h3>{title}</h3>
                    <span>{short == "login" ? "تم الخروج من الموقع لعدم الفعالية." : short}</span>
                </div>

                <a onClick={() => setopen(false)}><IoCloseOutline size={20} /></a>

                
            </div>

        </div>
    )
}
