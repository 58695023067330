import { useState } from 'react'
import { Link, useLocation } from "react-router-dom";

import { Img } from '../../components/Elements'

export default function BookList({ data }) {
    
    return (
        <Link target='_blank' to={`/book/${data?._id}`} className='bookBox'>
            <span>الجزء {data?.part?.val}</span>
            <Img src={data?.cover[0]}/>
            <nav>
                <h3>{data?.name?.name}</h3>
                <h4>الصف {globalThis.Classes[data?.classes[0]]}</h4>
            </nav>
        </Link>
    )
}
