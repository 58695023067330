import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";


import './css/Books.css'
import { AppContext } from "../Context";
import { Loader, GET } from '../inc/Fetch'
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

import BookList from './books/bookList';


export default function Books() {

	const active = useRef(true);
	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	
	async function loadData() { 

		setpageLoad(true);

		let res = await GET("books/view");
		setdata(res?.books);
		
		
		let arrCats = [{ name: `- جميع الكتب -`, val:false}];
		res?.book_name?.map((v) => {
			arrCats.push({ name: v?.name, val:v?._id});
		});
		setcats(arrCats);


		let arrClas = [{ name: `- جميع الصفوف -`, val:false}];
		Object.keys(global.Classes)?.map((v) => {
			arrClas.push({ name: `الصف ${global.Classes[v]}`, val:v});
		});
		setcls(arrClas);

		setpageLoad(false);

	}
	
	useEffect(() => {
		loadData();
	}, []);

	

	return (
		
		<>
			
			<Header />
			<div className='firstElem search'>
				<div className='mainWidth'>

					<div className='filterBoxTop'>

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cats}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                cats:v,
                            }))}
                            value={filter?.cats}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cls}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                classes:v,
                            }))}
                            value={filter?.classes}
                        />
			


					</div>

				</div>
			</div>


			<section style={{backgroundColor:"transparent"}} className='sect5 tasks gamesCont'>

				{pageLoad ? <Loader /> :

				<div className='mainWidth' style={{backgroundColor:"#fff"}} >
					<h1 className='boxTitle' style={{margin:"20px 0"}}>الكتب الديجيتالية</h1>
					
					<div className='bookListBox'>
						
						{data?.map((v, k) => {
							return (
								<>
									<BookList data={v} key={k} />
								</>
							);
						})}

					</div>
					
					
				</div>}
			</section>

			<Footer />
		</>
	)
}
