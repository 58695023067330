import { memo, useEffect, useState, useContext, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";

import './css/QuizPrev.css'
import manSrc from '../../images/moe.png'

import { Config } from '../../inc/Config'
import { AppContext } from "../../Context";
import Popups from "../../components/Popups";
import { Question } from "../../components/Elements";

import { SaveFav } from '../../inc/functions'
import Fetch, { Loader, dateToText } from "../../inc/Fetch";

import { RiQuestionAnswerLine } from "react-icons/ri";



export default memo(function QuizPrev({setopen, open, openTasks}) {

    const active = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();

    const {success, userinfo, loginForm, cartData} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;
    const [openLogin, setopenLogin] = loginForm;
    const [cart, setcart] = cartData; 

    const [floader, setfloader] = useState(true);
    const [loader, setloader] = useState(false);
    const [search, setsearch] = useState("");
    const [checkall, setcheckall] = useState(false);

    const [task, settask] = useState({});

    function loadData() {

        Fetch("Quiz/view_by_id", (d) => {
            if (!d?.task) {
                navigate("/login", { state: { previousLocation: location } } )
            } else {
                settask(d?.task);
            }

            setfloader(false);
            
        }, { _id: open?._id })
    }

    

    function checkAll() {

        let ck = cart?.filter(p => p?.ID == task?.questions[0]?.ID);
        if (ck?.length) {

            setcart(p => {

                let arr = [];
                p?.map((v) => {

                    let ck2 = task?.questions?.filter(p => p?.ID == v?.ID);
                    if (!ck2?.length) {
                        arr.push(v);
                    }
                });

                return arr
            });

        } else {
            let arr = [];
            task?.questions?.map((v) => {
                arr.push(v);
            });

            setcart(p => [...p, ...arr]);
        }
        
    }

    useEffect(() => {
        loadData() ;
    }, [])

    if (floader) {
        return <Loader />;
    }
    
    if (!task?.name) {
        return false;
    }
    
    return (

        <>
        
        <Popups setOpen={setopen} style={{maxWidth:700, borderTop:"4px solid #fd745a", maxHeight:"99vh"}}>
            {loader && <Loader />}
           <>

                <div className='popupBody interface data' >

                    <div className='quizPrevBox'>
                        <div className='quizInfo'>

                            <div className='left'>
                                <img src={`${Config?.imgUrl}img/300/${task?.img}`} />
                                <div className='infoText'>
                                    <h2>{task?.name}</h2>
                                    <p>{task?.full}</p>
                                    <div className='moreInfo'>
                                        <text><RiQuestionAnswerLine />الاسئلة: <b>{task?.questions?.length}</b></text>
                                        <text className='green'><RiQuestionAnswerLine />القسم: <b>{task?.cats[0]?.name}</b></text>
                                        <text className='blue'><RiQuestionAnswerLine />الدرجة: <b>{global.Classes[task?.classes[0]]}</b></text>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='filterQuestionsBox'>
                            <input type='search' name='q' value={search} onChange={e => setsearch(e?.target?.value)} placeholder='ابحث..' />

                            <Link onClick={checkAll}>اختيار الجميع</Link>
                        </div>

                        <div className='questionsBox'>
                            {!search && task?.story && 
                                <div dangerouslySetInnerHTML={{__html:`<h2>القصة</h2>${task?.story}`}} className='storyBox'></div>
                            }

                            {task?.questions?.map((v, k) => {
                                
                                let allowd = search ? false : true;
                                if (search) {
                                    let ck = v?.notes?.search(search);
                                    let ck2 = v?.file?.file_title?.search(search);
                                    let ck3 = v?.question?.search(search);
 
                 
                                    if (ck > -1 || ck2 > -1 || ck3 > -1) {
                                        allowd = true;
                                    } else {
                                        allowd = false;
                                    }
                                    
                                }
                       
                                if (allowd) {
                                    return (
                                        <Question v={v} k={k} key={k} />
                                    );
                                }
                            })}

                            
                            
                        </div>

                        <div className='quizBtnBox'>

                            <button onClick={() => setopen(false)} className='btn gray'>الغاء</button>
                            {logedIn?.userinfo?._id ? 
                                <>
                                    <Link target='_blank' to={`/TeacherTaskPrev/${task?._id}`} className='btn green'>مشاهدة المهمة ({cart?.length})</Link>
                                    <button onClick={() => { openTasks({questions: cart, story:task?.story }); setopen(false) }} className='btn '>ارسل كمهمة {cart?.length})</button>
                                    
                                </>
                            :
                                <button onClick={() => { setopenLogin(true); }} className='btn'>سجل الدخول</button>
                            }
                            
                        </div>
                        
                    </div>

                    
                </div>
            </> 

            

        </Popups>
        </>
    );
})