global.Classes = {
    1: "الاول",
    2: "الثاني",
    3: "الثالث",
    4: "الرابع",
    5: "الخامس",
    6: "السادس",
    7: "السابع",
    8: "الثامن",
    9: "التاسع",
    10: "العاشر",
    11: "الحادي عشر",
    12: "الاثني عشر",
};

global.Grades = {
    1: "أ",
    2: "ب",
    3: "ج",
    4: "د",
    5: "ه",
    6: "و",
    7: "ز",
};